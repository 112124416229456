import { FC, ReactNode, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { RootState } from '@/store';

import i18n from '@/utils/i18n';
import { LANGUAGES, LANGUAGES_ARRAY } from '@/utils/vars';

interface HandleI18nProps {
  children: ReactNode;
}

export const HandleI18n: FC<HandleI18nProps> = ({ children }) => {
  const { pathname, query, locale: routerLocale, asPath, push } = useRouter();
  const preferredLocale = useSelector(
    (state: RootState) => state.auth.user.preferred_locale
  );
  const isAuthed = useSelector((state: RootState) => state.auth.isAuthed);

  const isLocaleForced = () => {
    if (typeof window !== 'undefined') {
      const urlLocale = window.location.pathname.split('/')[1];
      return LANGUAGES_ARRAY.includes(urlLocale as LANGUAGES);
    }

    return false;
  };

  useEffect(() => {
    if (
      isAuthed &&
      routerLocale &&
      preferredLocale &&
      routerLocale !== preferredLocale &&
      !isLocaleForced()
    ) {
      push({ pathname, query }, asPath, { locale: preferredLocale });
    }
  }, [routerLocale, isAuthed, preferredLocale, asPath, pathname, query]);

  useEffect(() => {
    if (
      routerLocale &&
      !isAuthed &&
      !isLocaleForced() &&
      typeof window !== 'undefined'
    ) {
      const [browserLanguage] = navigator.language.split('-');

      if (
        browserLanguage !== routerLocale &&
        LANGUAGES_ARRAY.includes(browserLanguage as LANGUAGES)
      ) {
        push({ pathname, query }, asPath, { locale: browserLanguage });
      }
    }
  }, [isAuthed, asPath, routerLocale, pathname, query]);

  useEffect(() => {
    if (routerLocale && routerLocale !== i18n.language) {
      i18n.changeLanguage(routerLocale);
    }
  }, [routerLocale, i18n.language]);

  return <>{children}</>;
};
