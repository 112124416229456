import Router from 'next/router';

import { PUBLIC_ROUTES } from '@/utils/vars';

export const redirect = (to: string) => {
  if (typeof window !== 'undefined') {
    const {
      replace,
      query: { internal, redirect: redirectQuery }
    } = Router;

    if (typeof redirectQuery === 'string') {
      if (internal) {
        replace(redirectQuery);
      } else {
        window.location.replace(redirectQuery);
      }
    } else {
      replace(to);
    }
  }
};

export const isPublicRoute = (pathname = '') => {
  if (typeof window === 'undefined') {
    return PUBLIC_ROUTES.some((route) => pathname.includes(route));
  }

  return PUBLIC_ROUTES.some((route) => Router.pathname.includes(route));
};
