import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackbarType } from '@joovence/ui/components/Snackbar/helpers';

export interface SnackbarParams {
  type?: SnackbarType;
  text?: string;
  open: boolean;
}

const initialState: SnackbarParams = {
  type: 'info',
  text: '',
  open: false
};

const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    showAlert(
      state,
      { payload: { text, type } }: PayloadAction<Omit<SnackbarParams, 'open'>>
    ) {
      state.text = text;
      state.type = type;
      state.open = true;
    },
    closeAlert(state) {
      state.open = false;
    }
  }
});

export const snackbarActions = snackbar.actions;
export default snackbar.reducer;
