import { AxiosRequestHeaders, AxiosResponse } from 'axios';

import { User, UserAddress } from '@/types/api/user';

import { API } from '@/api-requests/instance';

import { ACCOUNT_STATUS, BASIC_RESPONSE } from '@/utils/vars';

const { axios } = API;

const getCSRFCookie = (): Promise<AxiosResponse> =>
  axios.get('auth/csrf-cookie');

interface GetAccountStatusParams {
  email: string;
}

const getAccountStatus = (
  params: GetAccountStatusParams
): Promise<AxiosResponse<ACCOUNT_STATUS>> =>
  axios.get<ACCOUNT_STATUS>('/v2/auth/status', { params });

interface LoginParams {
  email: string;
  password: string;
}

const login = (payload: LoginParams): Promise<AxiosResponse<User>> =>
  axios.post<User>('/v2/auth/login', payload);

const logout = (): Promise<AxiosResponse> => axios.get('/v2/auth/logout');

const getUser = (
  headers?: AxiosRequestHeaders | undefined
): Promise<AxiosResponse<User>> =>
  axios.get<User>('/v2/auth/user', { headers });

export interface UpdateUserParams {
  firstname?: string;
  lastname?: string;
  birthday?: string;
  gender?: string;
}

const updateUser = (payload: UpdateUserParams): Promise<AxiosResponse<User>> =>
  axios.put<User>('/v2/auth/user', payload);

const updateUserAddress = (
  payload: UserAddress
): Promise<AxiosResponse<UserAddress>> =>
  axios.post<UserAddress>('/v2/auth/address', payload);

interface SetPasswordParams {
  password: string;
  password_confirmation: string;
}

const setPassword = (
  payload: SetPasswordParams
): Promise<AxiosResponse<BASIC_RESPONSE>> =>
  axios.post<BASIC_RESPONSE>('/v2/auth/password', payload);

interface RequestResetPasswordParams {
  email: string;
}

const requestResetPassword = (
  payload: RequestResetPasswordParams
): Promise<AxiosResponse<BASIC_RESPONSE>> =>
  axios.post<BASIC_RESPONSE>('/v2/auth/password/email', payload);

interface ResetPasswordParams {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

const resetPassword = (
  payload: ResetPasswordParams
): Promise<AxiosResponse<BASIC_RESPONSE>> =>
  axios.post<BASIC_RESPONSE>('/v2/auth/password/reset', payload);

interface ResendEmailConfirmationParams {
  email: string;
}

const resendEmailConfirmation = (
  payload: ResendEmailConfirmationParams
): Promise<AxiosResponse<ACCOUNT_STATUS>> =>
  axios.post<ACCOUNT_STATUS>('/v2/auth/email/resend', payload);

const stopImpersonating = (): Promise<AxiosResponse> =>
  axios.delete('/v2/auth/user/impersonate');

export const authAPI = {
  getCSRFCookie,
  getAccountStatus,
  login,
  logout,
  getUser,
  updateUser,
  updateUserAddress,
  setPassword,
  requestResetPassword,
  resetPassword,
  resendEmailConfirmation,
  stopImpersonating
};
