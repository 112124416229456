import { FC, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import Link from 'next/link';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { RootState } from '@/store';

import { useJoovenceTheme } from '@/hooks/useJoovenceTheme';

import { ROUTES } from '@/utils/vars';

import { RootStyled } from './Styled';

const year = new Date().getFullYear();

interface BaseLayoutProps {
  children: ReactNode;
}

export const BaseLayout: FC<BaseLayoutProps> = ({ children }) => {
  const { t } = useTranslation('common');

  const isAuthed = useSelector(({ auth }: RootState) => auth.isAuthed);
  const isJoovence = useJoovenceTheme();

  return (
    <RootStyled isJoovence={isJoovence}>
      {isJoovence && (
        <header>
          <Link href={isAuthed ? ROUTES.profile : ROUTES.login} passHref>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <Image
                src="/assets/images/logo-joovence.svg"
                alt="Joovence Logo"
                width={488}
                height={53}
              />
            </a>
          </Link>
        </header>
      )}

      <main>{children}</main>

      {isJoovence && (
        <footer>
          <p>
            <span>{t(`common:common.allRightsReserved`)}</span>
            <a href="https://www.joovence.com">Joovence</a>
            <span> {year}</span>
          </p>
        </footer>
      )}
    </RootStyled>
  );
};
