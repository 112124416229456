import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { StatusType } from '@/types/common';
import { User, UserAddress } from '@/types/api/user';

import { UpdateUserParams } from '@/api-requests/auth';

import { ACCOUNT_STATUS, ADDRESS_TYPE, STATUS } from '@/utils/vars';

export interface AuthState {
  isAuthed: boolean;
  isUserFetched: boolean;
  impersonating: boolean;
  user: User;
  accountStatus: ACCOUNT_STATUS;
  status: StatusType;
  errors?: string;
}

const initialState: AuthState = {
  isAuthed: false,
  isUserFetched: false,
  impersonating: false,
  user: {} as User,
  accountStatus: '' as ACCOUNT_STATUS,
  status: STATUS.NOT_STARTED
};

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // ************************************************************************
    // Fetch user
    // ************************************************************************
    authUserFetchRequested(
      state,
      _action?: PayloadAction<
        | {
            onSuccess?: (user: User) => void;
            onError?: () => void;
          }
        | undefined
      >
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authUserFetchSucceeded(
      state,
      { payload }: PayloadAction<{ user: User; impersonating: boolean }>
    ) {
      state.user = payload.user;
      state.impersonating = payload.impersonating;
      state.isAuthed = true;
      state.isUserFetched = true;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authUserFetchFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
      state.isUserFetched = true;
    },

    // ************************************************************************
    // Fetch account status
    // ************************************************************************
    authAccountStatusFetchRequested(
      state,
      _action: PayloadAction<{
        email: string;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authAccountStatusFetchSucceeded(
      state,
      { payload }: PayloadAction<ACCOUNT_STATUS>
    ) {
      state.accountStatus = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authAccountStatusFetchFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Login
    // ************************************************************************
    authLoginRequested(
      state,
      _action: PayloadAction<{
        email: string;
        password: string;
        rememberMe: boolean;
        onError?: (error: unknown) => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authLoginSucceeded(
      state,
      { payload }: PayloadAction<{ user: User; impersonating: boolean }>
    ) {
      state.user = payload.user;
      state.impersonating = payload.impersonating;
      state.isAuthed = true;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authLoginFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Logout
    // ************************************************************************
    authLogoutRequested(state) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authLogoutSucceeded(state) {
      state.user = {} as User;
      state.isAuthed = false;
      state.accountStatus = '' as ACCOUNT_STATUS;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authLogoutFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Resend confirmation
    // ************************************************************************
    authResendConfirmationRequested(
      state,
      _action: PayloadAction<{
        email: string;
        onError?: (error: unknown) => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authResendConfirmationSucceeded(
      state,
      { payload }: PayloadAction<ACCOUNT_STATUS>
    ) {
      state.accountStatus = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authResendConfirmationFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Request reset password
    // ************************************************************************
    authRequestResetPasswordRequested(
      state,
      _action: PayloadAction<{
        email: string;
        onSuccess?: () => void;
        onError?: (error: unknown) => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authRequestResetPasswordSucceeded(state) {
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authRequestResetPasswordFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Reset password
    // ************************************************************************
    authResetPasswordRequested(
      state,
      _action: PayloadAction<{
        token: string;
        email: string;
        password: string;
        password_confirmation: string;
        onSuccess?: () => void;
        onError?: (error: unknown) => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authResetPasswordSucceeded(state) {
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authResetPasswordFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Set password
    // ************************************************************************
    authSetPasswordRequested(
      state,
      _action: PayloadAction<{
        password: string;
        password_confirmation: string;
        onSuccess?: () => void;
        onError?: (error: unknown) => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authSetPasswordSucceeded(state) {
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authSetPasswordFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Update personal data
    // ************************************************************************
    authUpdatePersonalDataRequested(
      state,
      _action: PayloadAction<{
        user: UpdateUserParams;
        onSuccess?: () => void;
        onError?: (error: unknown) => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authUpdatePersonalDataSucceeded(state, { payload }: PayloadAction<User>) {
      state.user = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authUpdatePersonalDataFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Update address
    // ************************************************************************
    authUpdateAddressRequested(
      state,
      _action: PayloadAction<{
        address: Record<ADDRESS_TYPE, UserAddress>;
        onSuccess?: () => void;
        onError?: (error: unknown) => void;
      }>
    ) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authUpdateAddressSucceeded(state, { payload }: PayloadAction<User>) {
      state.user = payload;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authUpdateAddressFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    },

    // ************************************************************************
    // Stop impersonating user
    // ************************************************************************
    authStopImpersonatingRequested(state) {
      state.status = STATUS.IN_PROGRESS;
      state.errors = '';
    },
    authStopImpersonatingSucceeded(state, { payload }: PayloadAction<User>) {
      state.user = payload;
      state.impersonating = false;
      state.status = STATUS.SUCCEEDED;
      state.errors = '';
    },
    authStopImpersonatingFailed(
      state,
      { payload }: PayloadAction<{ errorMessage: string }>
    ) {
      state.status = STATUS.FAILED;
      state.errors = payload.errorMessage;
    }
  }
});

export const authActions = auth.actions;
export default auth.reducer;
