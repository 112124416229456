import NextApp, { AppContext } from 'next/app';

import { SsrData } from '@/types/page';

import { API } from '@/api-requests/instance';
import { authAPI } from '@/api-requests/auth';

import { API_WHITE_LABEL_URL } from '@/utils/config';
import { ROUTES, SSR_AUTH_COOKIE_KEY } from '@/utils/vars';
import { getCookie } from '@/utils/cookie';
import { setServerSideHeaders } from '@/utils/ajax';
import { isPublicRoute } from '@/utils/redirect';
import { parseBooleanQuery } from '@/utils/format';

export const getInitialProps = async (ctx: AppContext) => {
  const appProps = await NextApp.getInitialProps(ctx);

  const {
    req,
    res,
    pathname,
    query: { whiteLabel }
  } = ctx.ctx;

  const ssrData: SsrData = {
    user: null,
    isJoovence: true,
    impersonating: false
  };

  const host = req?.headers.host || window?.location.host;

  if (host) {
    ssrData.isJoovence = host.includes('joovence');
  }

  const hasWhiteLabelQuery = parseBooleanQuery(whiteLabel as string);
  if (hasWhiteLabelQuery) {
    ssrData.isJoovence = false;
  }

  if (!req || !res || pathname === '/404') {
    return { ...appProps, ssrData };
  }

  const hasAuthedCookie = getCookie(SSR_AUTH_COOKIE_KEY, req.headers.cookie);
  const isAuthed = hasAuthedCookie ? JSON.parse(hasAuthedCookie) : false;

  if (isAuthed) {
    return { ...appProps, ssrData };
  }

  if (!ssrData.isJoovence) {
    API.axios.defaults.baseURL = API_WHITE_LABEL_URL;
  }

  try {
    await authAPI.getCSRFCookie();
    const serverSideHeaders = setServerSideHeaders(req);
    const { data: user, headers } = await authAPI.getUser(serverSideHeaders);

    ssrData.user = user;
    const impersonating = headers.impersonating
      ? JSON.parse(headers.impersonating)
      : false;
    ssrData.impersonating = impersonating;
    res.setHeader('Set-Cookie', `${SSR_AUTH_COOKIE_KEY}=true; Path=/`);
  } catch (err) {
    res.setHeader('Set-Cookie', `${SSR_AUTH_COOKIE_KEY}=false; Path=/`);

    if (!isPublicRoute(pathname)) {
      const query =
        pathname === ROUTES.logout ? '' : `?internal=true&redirect=${pathname}`;

      res.writeHead(302, {
        Location: `${ROUTES.login}${query}`
      });
      res.end();
    }

    ssrData.user = null;
  }

  return { ...appProps, ssrData };
};
