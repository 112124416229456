import { FC } from 'react';
import { LoadingScreen as LoadingScreenUI } from '@joovence/ui/components/LoadingScreen/LoadingScreen';
import { LoadingScreenProps } from '@joovence/ui/components/LoadingScreen/helpers';

import { useJoovenceTheme } from '@/hooks/useJoovenceTheme';

import { CircularProgressStyled } from './Styled';

export const LoadingScreen: FC<LoadingScreenProps> = ({ ...props }) => {
  const isJoovenceTheme = useJoovenceTheme();

  const logo = isJoovenceTheme ? undefined : (
    <CircularProgressStyled size={120} />
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LoadingScreenUI {...props} logo={logo} animate={isJoovenceTheme} />
  );
};
