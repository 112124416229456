import { FC, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import { User } from '@/types/api/user';

import { RootState } from '@/store';
import { authActions } from '@/store/auth';

import { ROUTES } from '@/utils/vars';
import { isPublicRoute } from '@/utils/redirect';

import { LoadingScreen } from '@/components/loading/LoadingScreen';

interface HandleAuthProps {
  children: ReactNode;
  user: User | null;
  impersonating: boolean;
}

export const HandleAuth: FC<HandleAuthProps> = ({
  children,
  user,
  impersonating
}) => {
  const { replace, pathname } = useRouter();

  const dispatch = useDispatch();
  const { isAuthed, isUserFetched } = useSelector(
    ({ auth }: RootState) => auth
  );

  const isPublic = isPublicRoute();
  const childrenVisible = isUserFetched && (isAuthed || isPublic);

  useEffect(() => {
    if (!isAuthed) {
      const redirectToProfile = () => {
        if (pathname === ROUTES.login) {
          replace(ROUTES.profile);
        }
      };

      if (user) {
        dispatch(authActions.authUserFetchSucceeded({ user, impersonating }));
        redirectToProfile();
      } else {
        const redirectToLogin = () => {
          if (!isPublic) {
            replace({
              pathname: ROUTES.login,
              query: {
                internal: true,
                redirect: pathname
              }
            });
          }
        };

        dispatch(
          authActions.authUserFetchRequested({
            onError: redirectToLogin,
            onSuccess: redirectToProfile
          })
        );
      }
    }
  }, [isAuthed, user, pathname, isPublic, pathname, impersonating]);

  return (
    <>
      <LoadingScreen visible={!childrenVisible} unmountOnHide />
      {childrenVisible && children}
    </>
  );
};
