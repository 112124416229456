import { PayloadAction } from '@reduxjs/toolkit';
import { call, put } from 'redux-saga/effects';

import { snackbarActions } from '@/store/snackbar';

import { gerErrorMessage } from '@/utils/format';

interface ReducerArgs {
  errorMessage: string;
}

type Reducer = (args: ReducerArgs) => PayloadAction<ReducerArgs>;

interface HandleErrorArgs {
  error: unknown;
  reducer: Reducer;
}

export function* handleError({ error, reducer }: HandleErrorArgs) {
  const errorMessage: string = yield call(gerErrorMessage, error);

  yield put(reducer({ errorMessage }));
  yield put(snackbarActions.showAlert({ type: 'error', text: errorMessage }));
}
