import * as Sentry from '@sentry/nextjs';
import { Integrations } from '@sentry/tracing';

import { SENTRY_DSN } from '@/utils/config';

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  integrations: [new Integrations.BrowserTracing()]
});
