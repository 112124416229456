import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import Router from 'next/router';
import { AxiosError, AxiosResponse } from 'axios';

import { RequestError } from '@/types/common';
import { User, UserAddress } from '@/types/api/user';

import { authAPI, UpdateUserParams } from '@/api-requests/auth';

import { authActions, AuthState } from '@/store/auth';
import { snackbarActions } from '@/store/snackbar';

import {
  ACCOUNT_STATUS,
  ADDRESS_TYPE,
  BASIC_RESPONSE,
  ROUTES
} from '@/utils/vars';
import { handleError } from '@/utils/redux';
import { gerErrorMessage } from '@/utils/format';
import { setCookie } from '@/utils/cookie';
import { redirect } from '@/utils/redirect';
import i18n from '@/utils/i18n';

function* fetchUserSaga(
  action?: PayloadAction<{
    onSuccess?: (user: User) => void;
    onError?: () => void;
  }>
) {
  try {
    const { isUserFetched } = yield select(
      ({ auth }: { auth: AuthState }) => auth
    );

    if (isUserFetched) {
      yield call(authAPI.getCSRFCookie);
    }
    const { data: user, headers }: AxiosResponse<User> = yield call(
      authAPI.getUser
    );

    const impersonating = headers?.impersonating
      ? JSON.parse(headers.impersonating)
      : false;
    yield put(authActions.authUserFetchSucceeded({ user, impersonating }));

    if (action?.payload?.onSuccess) {
      yield call(action.payload.onSuccess, user);
    }
  } catch (error) {
    if (action?.payload?.onError) {
      const errorMessage: string = yield call(gerErrorMessage, error);
      yield put(authActions.authUserFetchFailed({ errorMessage }));
      yield call(action.payload.onError);
    } else {
      yield call(handleError, {
        error,
        reducer: authActions.authUserFetchFailed
      });
    }
  }
}

function* fetchAccountStatusSaga({
  payload: { email }
}: PayloadAction<{ email: string }>) {
  try {
    const { data }: AxiosResponse<ACCOUNT_STATUS> = yield call(
      authAPI.getAccountStatus,
      { email }
    );
    yield put(authActions.authAccountStatusFetchSucceeded(data));
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: authActions.authAccountStatusFetchFailed
    });
  }
}

function* loginSaga({
  payload: { email, password, rememberMe, onError }
}: PayloadAction<{
  email: string;
  password: string;
  rememberMe: boolean;
  onError?: (error: unknown) => void;
}>) {
  try {
    yield call(authAPI.login, {
      email,
      password
    });
    const { data: user, headers }: AxiosResponse<User> = yield call(
      authAPI.getUser
    );
    const impersonating = headers?.impersonating
      ? JSON.parse(headers.impersonating)
      : false;
    yield put(authActions.authLoginSucceeded({ user, impersonating }));
    yield call(redirect, ROUTES.profile);

    if (rememberMe) {
      yield call(setCookie, 'email', email);
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: authActions.authLoginFailed
    });

    if (onError) {
      yield call(onError, error);
    }
  }
}

function* logoutSaga() {
  try {
    yield call(authAPI.logout);
    yield put(authActions.authLogoutSucceeded());
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: authActions.authLogoutFailed
    });
  } finally {
    if (typeof window !== 'undefined') {
      yield call(Router.replace, ROUTES.login);
    }
  }
}

function* resendConfirmationSaga({
  payload: { email, onError }
}: PayloadAction<{ email: string; onError?: (error: unknown) => void }>) {
  try {
    const { data }: AxiosResponse<ACCOUNT_STATUS> = yield call(
      authAPI.resendEmailConfirmation,
      { email }
    );
    yield put(authActions.authResendConfirmationSucceeded(data));
  } catch (error) {
    const accountStatus = (error as AxiosError<RequestError>)?.response
      ?.data as ACCOUNT_STATUS | undefined;

    if (accountStatus === ACCOUNT_STATUS.alreadyConfirmed) {
      yield call(handleError, {
        error: new Error(
          i18n.t('confirmAccount:confirmAccount.snackbar.success')
        ),
        reducer: authActions.authResendConfirmationFailed
      });
    } else {
      yield call(handleError, {
        error,
        reducer: authActions.authResendConfirmationFailed
      });
    }

    if (onError) {
      yield call(onError, error);
    }
  }
}

function* requestResetPasswordSaga({
  payload: { email, onSuccess, onError }
}: PayloadAction<{
  email: string;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}>) {
  try {
    const { data }: AxiosResponse<BASIC_RESPONSE> = yield call(
      authAPI.requestResetPassword,
      { email }
    );

    if (data === BASIC_RESPONSE.success) {
      yield put(authActions.authRequestResetPasswordSucceeded());

      if (onSuccess) {
        yield call(onSuccess);
      }
    } else {
      yield call(handleError, {
        error: new Error(
          i18n.t('password:password.requestResetPassword.snackbar.error')
        ),
        reducer: authActions.authRequestResetPasswordFailed
      });
    }
  } catch (error) {
    yield call(handleError, {
      error: new Error(
        i18n.t('password:password.requestResetPassword.snackbar.error')
      ),
      reducer: authActions.authRequestResetPasswordFailed
    });

    if (onError) {
      yield call(onError, error);
    }
  }
}

function* resetPasswordSaga({
  payload: { onSuccess, onError, ...form }
}: PayloadAction<{
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}>) {
  try {
    const { data }: AxiosResponse<BASIC_RESPONSE> = yield call(
      authAPI.resetPassword,
      form
    );

    if (data === BASIC_RESPONSE.success) {
      yield put(authActions.authResetPasswordSucceeded());

      if (onSuccess) {
        yield call(onSuccess);
      }
    } else {
      yield call(handleError, {
        error: new Error(
          i18n.t('password:password.resetPassword.snackbar.error')
        ),
        reducer: authActions.authResetPasswordFailed
      });
    }
  } catch (error) {
    yield call(handleError, {
      error: new Error(
        i18n.t('password:password.resetPassword.snackbar.error')
      ),
      reducer: authActions.authResetPasswordFailed
    });

    if (onError) {
      yield call(onError, error);
    }
  }
}

function* setPasswordSaga({
  payload: { onSuccess, onError, ...form }
}: PayloadAction<{
  password: string;
  password_confirmation: string;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}>) {
  try {
    const { data }: AxiosResponse<BASIC_RESPONSE> = yield call(
      authAPI.setPassword,
      form
    );

    if (data === BASIC_RESPONSE.success) {
      yield put(authActions.authSetPasswordSucceeded());

      if (onSuccess) {
        yield call(onSuccess);
      }
    } else {
      yield call(handleError, {
        error: new Error(
          i18n.t('password:password.setPassword.snackbar.error')
        ),
        reducer: authActions.authSetPasswordFailed
      });
    }
  } catch (error) {
    yield call(handleError, {
      error: new Error(i18n.t('password:password.setPassword.snackbar.error')),
      reducer: authActions.authSetPasswordFailed
    });

    if (onError) {
      yield call(onError, error);
    }
  }
}

function* updatePersonalDataSaga({
  payload: { user, onSuccess, onError }
}: PayloadAction<{
  user: UpdateUserParams;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}>) {
  try {
    yield call(authAPI.updateUser, user);
    const { data }: AxiosResponse<User> = yield call(authAPI.getUser);
    yield put(authActions.authUpdatePersonalDataSucceeded(data));
    yield put(
      snackbarActions.showAlert({
        text: i18n.t('profile:profile.edit.personalData.success'),
        type: 'success'
      })
    );

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: authActions.authUpdatePersonalDataFailed
    });

    if (onError) {
      yield call(onError, error);
    }
  }
}

function* updateAddressSaga({
  payload: { address, onSuccess, onError }
}: PayloadAction<{
  address: Record<ADDRESS_TYPE, UserAddress>;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
}>) {
  try {
    yield Promise.all(
      Object.entries(address).map(([key, value]) =>
        authAPI.updateUserAddress({ ...value, type: key as ADDRESS_TYPE })
      )
    );
    const { data }: AxiosResponse<User> = yield call(authAPI.getUser);
    yield put(authActions.authUpdatePersonalDataSucceeded(data));
    yield put(
      snackbarActions.showAlert({
        text: i18n.t('profile:profile.edit.addresses.success'),
        type: 'success'
      })
    );

    if (onSuccess) {
      yield call(onSuccess);
    }
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: authActions.authUpdatePersonalDataFailed
    });

    if (onError) {
      yield call(onError, error);
    }
  }
}

function* stopImpersonatingSaga() {
  try {
    yield call(authAPI.stopImpersonating);
    const { data }: AxiosResponse<User> = yield call(authAPI.getUser);
    yield put(authActions.authStopImpersonatingSucceeded(data));
  } catch (error) {
    yield call(handleError, {
      error,
      reducer: authActions.authStopImpersonatingFailed
    });
  }
}

export default function* watcherAuthSaga(): Generator {
  yield takeLatest(authActions.authUserFetchRequested, fetchUserSaga);
  yield takeLatest(
    authActions.authAccountStatusFetchRequested,
    fetchAccountStatusSaga
  );
  yield takeLatest(authActions.authLoginRequested, loginSaga);
  yield takeEvery(authActions.authLogoutRequested, logoutSaga);
  yield takeLatest(
    authActions.authResendConfirmationRequested,
    resendConfirmationSaga
  );
  yield takeLatest(
    authActions.authRequestResetPasswordRequested,
    requestResetPasswordSaga
  );
  yield takeLatest(authActions.authResetPasswordRequested, resetPasswordSaga);
  yield takeLatest(authActions.authSetPasswordRequested, setPasswordSaga);
  yield takeLatest(
    authActions.authUpdatePersonalDataRequested,
    updatePersonalDataSaga
  );
  yield takeLatest(authActions.authUpdateAddressRequested, updateAddressSaga);
  yield takeLatest(
    authActions.authStopImpersonatingRequested,
    stopImpersonatingSaga
  );
}
