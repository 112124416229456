import { AxiosError, AxiosResponse } from 'axios';
import { FormikErrors } from 'formik';

import { RequestError } from '@/types/common';

export const gerErrorMessage = (error: unknown): string => {
  if ((error as AxiosError<RequestError>)?.response?.data?.message) {
    if ((error as AxiosError<RequestError>)?.response?.data?.errors) {
      const errors = Object.values(
        (
          (error as AxiosError<RequestError>)
            .response as AxiosResponse<RequestError>
        ).data.errors
      ).join(' ');

      return `${
        (error as AxiosError<RequestError>)?.response?.data?.message
      } ${errors}`;
    }
    return (error as AxiosError<RequestError>)?.response?.data
      ?.message as string;
  }
  if ((error as Error)?.message) {
    return (error as Error).message;
  }
  return error as string;
};

interface GetServerSideErrors<T> {
  error: unknown;
  fields: (keyof T)[];
}

export function getServerSideErrors<T>({
  error,
  fields
}: GetServerSideErrors<T>): FormikErrors<T> {
  const errors: FormikErrors<T> = {};

  const serverSideErrors = (error as AxiosError<RequestError>)?.response?.data
    ?.errors;

  if (serverSideErrors) {
    fields.map((field) => {
      // @ts-ignore
      const [fieldError] = serverSideErrors[field] || [];
      errors[field] = fieldError;

      return field;
    });
  }

  return errors;
}

export const parseBooleanQuery = (query: string): boolean => {
  try {
    const parsedQuery = JSON.parse(query);
    if (typeof parsedQuery === 'boolean') {
      return parsedQuery;
    }
    return false;
  } catch (_error) {
    return false;
  }
};
