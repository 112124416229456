import common from './common.json';
import profile from './profile.json';
import password from './password.json';
import confirmAccount from './confirmAccount.json';
import login from './login.json';
import validation from './validation.json';

export const it = {
  common,
  profile,
  password,
  confirmAccount,
  login,
  validation
};
