export enum LANGUAGES {
  EN = 'en',
  FR = 'fr',
  IT = 'it'
}

export const LANGUAGES_ARRAY = Object.values(LANGUAGES);

export enum COUNTRIES {
  FR = 'FR',
  BE = 'BE',
  ES = 'ES',
  IT = 'IT',
  GP = 'GP'
}

export const COUNTRIES_ARRAY = Object.values(COUNTRIES);

export enum BASIC_RESPONSE {
  success = 'success',
  failed = 'failed'
}

export enum ROUTES {
  login = '/login',
  logout = '/logout',
  profile = '/profile',
  confirm = '/confirm',
  resetPassword = '/reset-password',
  setPassword = '/set-password',
  personalData = '/profile/personal-data/edit',
  address = '/profile/addresses/edit'
}

export const PUBLIC_ROUTES = [
  ROUTES.login,
  ROUTES.confirm,
  ROUTES.resetPassword
];

export enum STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED'
}

export const SSR_AUTH_COOKIE_KEY = 'joovence_auth_ssr';

export enum ACCOUNT_STATUS {
  confirmed = 'confirmed',
  notFound = 'not_found',
  notConfirmed = 'not_confirmed',
  confirmationSent = 'confirmation_sent',
  alreadyConfirmed = 'already_confirmed'
}

export enum GENDERS {
  male = 'm',
  female = 'f',
  notDefined = 'u'
}

export const GENDERS_ARRAY = Object.values(GENDERS);

export enum ADDRESS_TYPE {
  main = 'main',
  delivery = 'delivery'
}

export const ADDRESS_TYPES_ARRAY = Object.values(ADDRESS_TYPE);

export enum USER_TYPE {
  joovence = 'joovence',
  whiteLabel = 'white_label'
}
