/* eslint-disable import/no-duplicates */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import {
  format as formatDate,
  formatDistance,
  formatRelative,
  isDate,
  Locale
} from 'date-fns';
import { enUS, fr as dateFr, it as dateIt } from 'date-fns/locale';

import { LANGUAGES } from '@/utils/vars';

import { en } from '@/lang/en';
import { fr } from '@/lang/fr';
import { it } from '@/lang/it';

const resources = {
  en,
  fr,
  it
};

const locales: Record<LANGUAGES, Locale> = {
  en: enUS,
  fr: dateFr,
  it: dateIt
};

i18n.use(initReactI18next).init({
  resources,
  lng: LANGUAGES.EN,

  interpolation: {
    escapeValue: false,

    format: (value, format, lng) => {
      if (isDate(value) && lng && format) {
        const locale = locales[lng as LANGUAGES];

        if (format === 'relative') {
          return formatRelative(value, new Date(), { locale });
        }

        if (format === 'ago') {
          return formatDistance(value, new Date(), {
            locale,
            addSuffix: true
          });
        }

        return formatDate(value, format, { locale });
      }

      return value;
    }
  }
});

export default i18n;
