import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';

import { API_URL, API_WHITE_LABEL_URL } from '@/utils/config';

const createAPI = (config?: AxiosRequestConfig) => ({
  axios: axios.create(config)
});

const addRequestInterceptor = (instance: AxiosInstance) => {
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      if (typeof window !== 'undefined') {
        if (window.location.hostname.includes('joovence')) {
          config.baseURL = API_URL;
        } else {
          config.baseURL = API_WHITE_LABEL_URL;
        }
      }

      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );
};

export const API = createAPI({
  baseURL: API_URL,
  withCredentials: true
});

addRequestInterceptor(API.axios);
