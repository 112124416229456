import { useEffect } from 'react';
import Head from 'next/head';
import { Provider as ReduxProvider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import { AppProps } from '@/types/page';

import { store } from '@/store';

import { getInitialProps } from '@/ssr/getInitialProps';

import i18n from '@/utils/i18n';
import { cleanupSsrCookiesBeforeUnload } from '@/utils/cookie';

import { BaseLayout } from '@/components/layouts/BaseLayout/BaseLayout';
import { EmptyLayout } from '@/components/layouts/EmptyLayout';
import { ThemeProvider } from '@/components/theme/ThemeProvider';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { Snackbar } from '@/components/Snackbar';
import { HandleAuth } from '@/components/HandleAuth';
import { HandleI18n } from '@/components/HandleI18n';

import '@/styles/index.css';

function App({
  Component,
  pageProps,
  ssrData: { isJoovence, impersonating, user }
}: AppProps): JSX.Element {
  const OuterLayout = Component.OuterLayout || BaseLayout;
  const InnerLayout = Component.InnerLayout || EmptyLayout;
  const title = isJoovence ? 'Auth - Joovence' : 'Auth';

  useEffect(() => {
    cleanupSsrCookiesBeforeUnload();
  }, []);

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={isJoovence ? '/favicon.png' : '/favicon-white.png'}
        />
      </Head>

      <I18nextProvider i18n={i18n}>
        <ThemeProvider isJoovence={isJoovence}>
          <ErrorBoundary>
            <ReduxProvider store={store}>
              <HandleAuth user={user} impersonating={impersonating}>
                <HandleI18n>
                  <OuterLayout>
                    <InnerLayout>
                      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                      <Component {...pageProps} />
                    </InnerLayout>
                  </OuterLayout>
                </HandleI18n>
              </HandleAuth>
              <Snackbar />
            </ReduxProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </I18nextProvider>
    </>
  );
}

App.getInitialProps = getInitialProps;

export default App;
