import { FC, ReactNode } from 'react';
import { ThemeProvider as ThemeProviderUI } from '@joovence/ui/components/ThemeProvider';
import { CacheProvider, CssBaseline } from '@joovence/ui/libExports';

import { emotionCache, IsJoovenceThemeContext } from './helpers';

interface ThemeProviderProps {
  children: ReactNode;
  isJoovence: boolean;
}

export const ThemeProvider: FC<ThemeProviderProps> = ({
  children,
  isJoovence
}) => {
  return (
    <CacheProvider value={emotionCache}>
      <ThemeProviderUI isMonochrome={!isJoovence}>
        <CssBaseline />
        <IsJoovenceThemeContext.Provider value={isJoovence}>
          {children}
        </IsJoovenceThemeContext.Provider>
      </ThemeProviderUI>
    </CacheProvider>
  );
};
